/* eslint-disable */
// TypeScriptContractGenerator's generated content

export type PageTranslationStatisticsCalculateRequest = {
    webSiteId: string;
    pagePaths?: null | Array<null | string>;
    locales?: null | Array<null | string>;
};
export type WebPageTranslationsCleanupRequest = {
    webSiteId: string;
    pageUrl: string;
    locale: string;
    dryRun?: null | boolean;
    deleteUnconfirmedOnly: boolean;
    preserveFragments?: null | Array<null | WebPageFragment>;
};
export type WebPageTranslationsCleanupResponse = {
    isComplete: boolean;
    removedFromTranslator?: null | Array<null | FragmentTranslation>;
    removedFromSmartcat?: null | Array<null | FragmentTranslation>;
};
export type PageTranslationInfoByPathRequest = {
    webSiteId: string;
    path?: null | Array<null | string>;
};
export type PageTranslationSelection = {
    selectionMode: PageTranslationSelectionMode;
    explicitSelection?: null | ExplicitPageTranslationSelection;
    queryBasedSelection?: null | QueryBasedPageTranslationSelection;
};
export type AddSectionRequest = {
    webSiteId: string;
    pageUrl?: null | string;
    sectionUrl?: null | string;
    fragmentTranslationToken?: null | string;
};
export type SmartcatProjectExportModel = {
    id: string;
    accountId: string;
    name?: null | string;
    domainId?: null | number;
    clientId?: null | string;
    creationDate: (Date | string);
    sourceLanguageId: number;
    targetLanguageIds?: null | number[];
    vendorAccountIds?: null | IReadOnlyCollection<string>;
    numbersByAccountId?: null | IDictionary<string, string>;
    isDeleted: boolean;
    createdByAccountUserId?: null | string;
};
export type PageTranslationStatisticsUpdateRequest = {
    webSiteId: string;
    absoluteUrl?: null | string;
    pagePath?: null | string;
    translations?: null | Array<null | PageTranslationStatisticsUpdate>;
};
export type PageTranslationStatisticsUpdate = {
    locale?: null | string;
    segmentCount?: null | number;
    autoTranslationCount?: null | number;
    scTranslationCount?: null | number;
    sourceWordsCount?: null | number;
    translatedWordsCount?: null | number;
};
export type PageInfo = {
    locales?: null | Array<null | string>;
    pagePath?: null | string;
    sections?: null | Array<null | string>;
};
export type PageTranslationInfo = {
    sourceLanguage?: null | string;
    lastCrawlError?: null | string;
    lang?: null | string;
    pagePath?: null | string;
    sortKey?: null | number;
    humanTranslatePercent: number;
    autoTranslatedPercent: number;
    wordCount: number;
    documentId?: null | string;
    sourceFragmentCount: number;
    autoTranslatedFragmentCount: number;
    humanTranslatedSegmentCount: number;
    lastPublishDate?: null | (Date | string);
    lastUpdateDate?: null | (Date | string);
    createdAt?: null | (Date | string);
};
export type PageTranslationDto = {
    lang?: null | string;
    pagePath?: null | string;
    sortKey?: null | number;
    humanTranslatePercent: number;
    autoTranslatedPercent: number;
    wordCount: number;
    documentId?: null | string;
    sourceFragmentCount: number;
    autoTranslatedFragmentCount: number;
    humanTranslatedSegmentCount: number;
    lastPublishDate?: null | (Date | string);
    lastUpdateDate?: null | (Date | string);
    createdAt?: null | (Date | string);
};
export type PageTranslationInfoRequest = {
    query?: null | string;
    webSiteId: string;
    sortField: PageSortField;
    sortDirection: SortDirection;
    batchSize: number;
    batchKey?: null | string;
};
export type WebPagePublishInfoResponse = {
    hasUnpublishedChanges: boolean;
};
export type WebPagePublishResponse = {
    taskIds?: null | string[];
};
export type SinglePagePublishTaskInfo = {
    isCompleted: boolean;
    isFailed: boolean;
};
export type WebSiteDetectLanguageRequest = {
    webSiteId: string;
    text?: null | string;
};
export type DetectLanguageResponse = {
    language?: null | string;
};
export type LanguageSelectorOffsetsParams = {
    top: number;
    bottom: number;
    right: number;
};
export type WebPageTranslateRequestV1 = {
    webSiteId?: null | string;
    pageUrl: string;
    locale: string;
    fragments: Array<null | WebPageFragmentToTranslate>;
    basePageUrl?: null | string;
    ignorePageLimits: boolean;
    fragmentTranslationToken?: null | string;
    translationMode: WebTranslationMode;
};
export type PageScriptPublishRequestDto = {
    webSiteId: string;
    pageUrl?: null | string;
    locales?: null | Array<null | string>;
};
export type WebSiteRegistrationRequest = {
    scUserId?: null | string;
    mtProfileId?: null | string;
    accountId: string;
    name: string;
    rootUrl: string;
    sourceLanguage: string;
    targetLanguages: Array<null | string>;
};
export type MixpanelSettings = {
    corporateAppToken?: null | string;
    usersAppToken?: null | string;
};
export type WebTranslationMixpanelEvent = {
    eventId?: null | string;
    eventParams?: null | {
        [key: string]: object;
    };
};
export type WebPageTranslateResponseV1 = {
    fragments?: null | Array<null | TranslatedWebPageFragmentV1>;
};
export type ScriptRendererConfigV1 = {
    webSiteId?: null | string;
    scriptVersion?: null | string;
    originalHost?: null | string;
    rootUrl?: null | string;
    sourceLocale?: null | string;
    defaultLocale?: null | string;
    locales?: null | Array<null | string>;
    langs?: null | Array<null | InjectedCodeLang>;
    fragmentTranslationUrlFormat?: null | string;
    translatorApiBaseUrl?: null | string;
    pageTranslationUrlFormat?: null | string;
    pageInfoUrlFormat?: null | string;
    addSectionUrlFormat?: null | string;
    pageUrl?: null | string;
    fragmentTranslationToken?: null | string;
    autoExportFragments: boolean;
    autoTranslate: boolean;
    autoDetectUserLanguage: boolean;
    translateNewElements: boolean;
    webTranslationMode: WebTranslationMode;
    languageSelectorLocation: LanguageSelectorLocation;
    languageSelectorOffsets?: null | LanguageSelectorOffsetsParams;
    showWatermark: boolean;
    ignorePageLimits: boolean;
    enableServiceWorker: boolean;
    useCanonicalLinks: boolean;
    fragmentExportUrlFormat?: null | string;
    extractionMode: HtmlKeyExtractionMode;
    loadTranslationsAsynchronously: boolean;
    pathRulesToTranslate?: null | Array<null | string>;
    dynamicSectionsEnabled: boolean;
};
export type DemoWebSiteCreateRequest = {
    rootUrl: string;
    sourceLanguage: string;
    targetLanguages: Array<null | string>;
};
export type PageScriptPublishRequest = {
    webSiteId: string;
    pagePath?: null | string;
    locales?: null | Array<null | string>;
};
export type SmartcatSyncResult = {
    items?: null | Array<null | SmartcatSyncResultItem>;
};
export type CurrentSettingsDto = {
    currentUserId?: null | string;
    currentAccountId?: null | string;
    smartcatApiUrl?: null | string;
};
export type SmartcatSyncRequest = {
    webSiteId: string;
    pagePath?: null | string;
    fragments?: null | Array<null | WebPageFragment>;
    targetLanguages?: null | Array<null | string>;
    force: boolean;
};
export type SmartcatSyncTaskResponse = {
    taskIds?: null | string[];
};
export type FragmentsExportRequestV1 = {
    webSiteId: string;
    exportToLocale?: null | string;
    pageUrl?: null | string;
    fragments?: null | Array<null | WebPageFragment>;
};
export type WebSitePublishInfo = {
    tasks?: null | Array<null | WebSitePublishTaskInfo>;
};
export type WebSitePublishInfoResponse = {
    translatorScriptUploaded: boolean;
};
export type SmartWordsInfo = {
    currentCount: number;
    monthlyCount: number;
    subscriptionLevel?: null | SubscriptionLevel;
};
export type GetDataItemsResponse<T> = {
    nextBatchKey?: null | string;
    items?: null | Array<null | T>;
};
export type SmartcatSyncRequestDto = {
    webSiteId: string;
    pageUrl?: null | string;
    pagePath?: null | string;
    fragments?: null | Array<null | WebPageFragment>;
    targetLang?: null | Array<null | string>;
    force: boolean;
};
export type DemoWebSiteCreateResponse = {
    webSiteId?: null | string;
    webSiteUrl?: null | string;
};
export enum ScriptPublicationValidationResult {
    ScriptIsOk = 'ScriptIsOk',
    ScriptIsMissing = 'ScriptIsMissing',
    ScriptDuplicates = 'ScriptDuplicates',
    ScriptBadAttributes = 'ScriptBadAttributes',
    ScriptInvalidUrl = 'ScriptInvalidUrl',
    CanonicalLinkMustBeBeforeScript = 'CanonicalLinkMustBeBeforeScript',
}
export type PageFilter = {
    pageUrls?: null | Array<null | string>;
    pageIds?: null | string[];
};
export type WebSiteInfo = {
    id: string;
    host?: null | string;
    rootUrl?: null | string;
    cdn?: null | string;
    scProjectId?: null | string;
    proxyUrlFormat?: null | string;
    serviceUrl?: null | string;
    sourceLanguage?: null | string;
    targetLanguages?: null | Array<null | string>;
    scUserId?: null | string;
    accountId?: null | string;
    isSharingEnabled: boolean;
    settings?: null | WebSiteSettingsDto;
};
export enum LanguageSelectorLocation {
    BottomRight = 'BottomRight',
    BottomLeft = 'BottomLeft',
    TopLeft = 'TopLeft',
    TopRight = 'TopRight',
    None = 'None',
}
export enum ClearTranslationsMode {
    None = 'None',
    OnlyMissing = 'OnlyMissing',
    All = 'All',
}
export type LatestPageTranslationsRequest = {
    webSiteId: string;
    batchKey?: null | string;
};
export type WebSiteSettingsDto = {
    general?: null | GeneralSettings;
    preview?: null | PreviewSettings;
    publishing?: null | PublishingSettings;
    crawling?: null | CrawlingSettings;
    translation?: null | TranslationSettings;
};
export type PreviewSettings = {
    autoTranslateInPreview: boolean;
    autoPublishInPreview: boolean;
};
export type GeneralSettings = {
    omitHostInDocumentNames: boolean;
    useUnderscoreAsLocaleSeparator: boolean;
    useSmartcatFolders: boolean;
    useCanonicalLinks: boolean;
    shortenPathSegments: boolean;
    autoDetectUserLanguage: boolean;
    translateNewElements: boolean;
    languageSelectorLocation: LanguageSelectorLocation;
    languageSelectorOffsets?: null | LanguageSelectorOffsetsParams;
    showWatermark: boolean;
    useLocJson: boolean;
    autoExportFragments: boolean;
    scriptVersion?: null | string;
    extractionMode: HtmlKeyExtractionMode;
    disableLanguageAddition: boolean;
};
export type CrawlingSettings = {
    customSitemaps?: null | Array<null | string>;
};
export type CountSmartwordsResponse = {
    count: number;
};
export type CountSmartwordsRequest = {
    texts?: null | Array<null | string>;
    sourceLanguage?: null | string;
    targetLanguage?: null | string;
};
export type SectionWebPageFragmentsCollection = {
    sectionUrl?: null | string;
    fragments?: null | Array<null | WebPageFragment>;
};
export type WebSiteStartCrawlRequest = {
    webSiteId: string;
    pathRulesToCrawl?: null | Array<null | string>;
    targetLanguages?: null | Array<null | string>;
    clearTranslationsMode: ClearTranslationsMode;
};
export type WebSiteStartCrawlResponse = {
    started: boolean;
};
export type WebSiteCrawlInfoResponse = {
    crawlInProgress: boolean;
    lastCrawlDate: (Date | string);
    sessionId?: null | string;
    status?: null | CrawlSessionStatus;
    statistics?: null | CrawlSessionStatisticsDto;
};
export type WebSiteStatsInfo = {
    wordCount: number;
    pageCount: number;
    webSiteId: string;
};
export type WebPageFragment = {
    text?: null | string;
    nodePath?: null | string;
};
export type FragmentTranslation = {
    nodePath?: null | string;
    sourceSegments?: null | Array<null | string>;
    segmentTranslations?: null | Array<null | string>;
    translationSource: FragmentTranslationSource;
    translationStatus?: null | string;
    updatedAt: (Date | string);
    hasTranslation: boolean;
    sourceLang?: null | string;
    isConfirmed: boolean;
};
export enum PageTranslationSelectionMode {
    Explicit = 'Explicit',
    QueryBased = 'QueryBased',
}
export type ExplicitPageTranslationSelection = {
    selectedItems?: null | Array<null | SelectedPageTranslationLanguage>;
};
export type QueryBasedPageTranslationSelection = {
    query?: null | string;
    excludeItems?: null | Array<null | SelectedPageTranslationLanguage>;
};
export type IReadOnlyCollection<T> = {
    count: number;
};
export type IDictionary<TKey, TValue> = {
    item?: null | TValue;
    keys?: null | ICollection<TKey>;
    values?: null | ICollection<TValue>;
};
export enum PageSortField {
    PagePath = 'PagePath',
    Language = 'Language',
    AutoTranslatedPercent = 'AutoTranslatedPercent',
    HumanTranslatedPercent = 'HumanTranslatedPercent',
    PublishDate = 'PublishDate',
    CreateDate = 'CreateDate',
    WordCount = 'WordCount',
}
export enum SortDirection {
    Asc = 'Asc',
    Desc = 'Desc',
}
export type WebPageFragmentToTranslate = {
    text?: null | string;
    nodePath?: null | string;
    sourceLang?: null | string;
    autoTranslateOnly: boolean;
};
export enum WebTranslationMode {
    Preview = 'Preview',
    Production = 'Production',
}
export type TranslatedWebPageFragmentV1 = {
    sourceLang?: null | string;
    sourceText?: null | string;
    translation?: null | string;
    nodePath?: null | string;
    translationSource: FragmentTranslationSource;
};
export type InjectedCodeLang = {
    isPublished: boolean;
    isSource: boolean;
    name?: null | string;
    locale?: null | string;
    rtl: boolean;
};
export enum HtmlKeyExtractionMode {
    TextWithXPath = 'TextWithXPath',
    TextOnly = 'TextOnly',
}
export type SmartcatSyncResultItem = {
    pagePath?: null | string;
    lang?: null | string;
    smartcatDocumentId?: null | string;
    errorDetails?: null | ErrorDetails;
    translationImportResult?: null | InsertionResult;
};
export type WebSitePublishTaskInfo = {
    taskId: string;
    completeCount: number;
    pathCount: number;
    isUnfinished: boolean;
    taskState: TaskState;
};
export enum SubscriptionLevel {
    Free = 'Free',
    Undefined = 'Undefined',
    Starter = 'Starter',
    Rise = 'Rise',
    Unite = 'Unite',
    Enterprise = 'Enterprise',
}
export type PublishingSettings = {
    langSettings?: null | Array<null | PublishLangSettings>;
    loadTranslationsAsynchronously: boolean;
};
export type TranslationSettings = {
    pathRulesToTranslate?: null | Array<null | string>;
};
export enum CrawlSessionStatus {
    Started = 'Started',
    AnalyzingRobotsTxt = 'AnalyzingRobotsTxt',
    AnalyzingSitemap = 'AnalyzingSitemap',
    Crawling = 'Crawling',
    Finished = 'Finished',
    FinishedWithErrors = 'FinishedWithErrors',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}
export type CrawlSessionStatisticsDto = {
    totalPagesFound: number;
    totalPagesCrawled: number;
    newPagesFound: number;
    updatedPagesFound: number;
    totalWordsFound: number;
    totalWordsTranslated: number;
    totalPagesFailedToCrawl: number;
};
export enum FragmentTranslationSource {
    MT = 'MT',
    SmartcatConfirmed = 'SmartcatConfirmed',
    Source = 'Source',
    SmartcatUnconfirmed = 'SmartcatUnconfirmed',
}
export type SelectedPageTranslationLanguage = {
    lang?: null | string;
    path?: null | string;
};
export type ICollection<T> = {
    count: number;
    isReadOnly: boolean;
};
export type ErrorDetails = {
    message?: null | string;
    details?: null | string;
    correlationId?: null | string;
    clientCode?: null | string;
};
export type InsertionResult = {
    isEmpty: boolean;
    newItemCount: number;
    updatedItemCount: number;
};
export enum TaskState {
    Sleep = 'Sleep',
    InProgress = 'InProgress',
    Closed = 'Closed',
    Manual = 'Manual',
    Suspended = 'Suspended',
}
export type PublishLangSettings = {
    lang?: null | string;
    publish: boolean;
};
