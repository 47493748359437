import { CustomizationBase } from '@/script/logic/customization/customization-base';
import { currencyRegex, taxesRegex } from '@/script/logic/customization/shopify-customization';

export const trademarkRegex = /^®|™/g

export abstract class ShopifyCustomizationV2 extends CustomizationBase {
	getAutoExportAudiencePercent(): number | undefined {
		return 0.1;
	}

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			const el = node as HTMLElement;
			if (el.childNodes.length == 1 && el.childNodes[0].nodeType == 3) {
				return this.isInvalidText(node.textContent);
			}
		} else if (node.nodeType === 3) {
			return this.isInvalidText(node.textContent);
		}
		return false;

	}

	isInvalidText(t: string): boolean {
		return !!(t.match(currencyRegex) || t.match(taxesRegex) || t.match(trademarkRegex));
	}

}
