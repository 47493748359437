<template>
	<div v-if="authorizationRequired" style="padding: 150px; width: 100%; text-align: center">
		<h1>
			Access denied.
		</h1>
		<div>
			Please sign in to continue.
		</div>
		<br/>
		<ScButton
			view="simple"
			@click="signIn">
			Sign In
		</ScButton>
	</div>
	<BrowserLayout
		v-else
		:is-loading="(translatedFrame && !translatedFrame.isLoaded) || initialSyncInProgress"
		:error="frameLoadingFailed"
	>
		<template #toolbar>
			<BrowserToolbar @close="openProject">
				<template #smartwords>
					<SmartwordIndicator
						v-if="smartwordInfo"
						:max-smartwords="smartwordInfo.monthlyCount"
						:current-smart-words-usage-count="smartwordInfo.currentCount"
						:subscription-name="smartwordInfo.subscriptionLevel"
						@click-top-up-balance="topUpBalance"
						@click-go-subscribtion="goToSubscription"
						@click-go-faq="goToFaq"
					/>
				</template>

				<template
					v-if="buttonState"
					#buttons
				>
					<!--					FRAME={{ frame==null }}-->
					<!--					ISLOADED={{ translatedFrame && translatedFrame.isLoaded }}-->
					<!--					initialSyncInProgress={{ initialSyncInProgress }}-->

					<WtButton
						v-if="notSourceLang && buttonState.showTranslate || !settingsAllowPageTranslation"
						:async-action="() => beginTranslation()"
						:disabled="buttonState.disableButtons || !settingsAllowPageTranslation"
						icon="magic"
						label="Start Translation"
						:tooltip="!settingsAllowPageTranslation ? 'Translation is disabled for this page by translation rules in settings' : null"
					/>
					<EditDropdown
						v-if="buttonState.showEdit && translationProgress.sections && translationProgress.sections.length>0"
						:disabled="buttonState.disableButtons"
						:loading="editInProgress"
						:options="(translationProgress.sections ||[]).map(x=>x.sectionId)"
						@edit-click="editPage"
						@edit-item="editSection"
					/>
					<WtButton
						v-else-if="notSourceLang && buttonState.showEdit"
						:async-action="editPage"
						:disabled="buttonState.disableButtons"
						icon="edit"
						label="Edit"
					/>
					<WtButton
						v-if="notSourceLang && buttonState.showPublish"
						:async-action="() => publish()"
						:disabled="buttonState.disableButtons"
						:badge="webPagePublishInfo && webPagePublishInfo.hasUnpublishedChanges"
						icon="upload"
						label="Publish"
					/>
					<WtButton
						v-if="showCleanupButton"
						:async-action="() => cleanUpTranslations()"
						:disabled="buttonState.disableButtons"
						:label="cleanupButtonText"
					/>
					<WtButton
						v-if="webSite && buttonState.showSettings"
						@click="openDashboard"
						label="Dashboard"
						icon="lqa-general"
						:disabled="buttonState.disableButtons"
					/>
					<WtButton
						v-if="webSite && buttonState.showSettings"
						icon="gear"
						view="flat"
						:disabled="buttonState.disableButtons"
						@click="showSettingsPopup"
					/>
				</template>
				<template
					v-if="webSite"
					#navigation
				>
					<BrowserNavigation
						:disable-language-addition="webSite.settings.general.disableLanguageAddition"
						:backward-disabled="!historyTracker.canGoBackward"
						:forward-disabled="!historyTracker.canGoForward"
						:lang-list-is-loading="langsSynchronizer ? langsSynchronizer.isSyncInProgress : false"
						:lang-is-adding="langIsAdding"
						:langs="langs"
						:stats="translationProgress"
						:machine-translation-spin="translatedFrame ? translatedFrame.isProxyTranslating : false"
						:selected-lang="targetLanguage"
						:source-lang="webSite.sourceLanguage"
						:sync-project-action="sync"
						:url="pageUrl"
						@navigate="onManualUrlInput"
						@click-back="onClickBack"
						@click-forward="onClickForward"
						@show-lang-panel="langsSynchronizer.sync()"
						@select-lang="onSelectLang"
					/>
				</template>
			</BrowserToolbar>
			<Notifications/>
			<ConfirmationsContainer/>
			<Transition name="popup">
				<PurchasePopup
					v-if="purchasePopupVisible"
					:purchase-smartword-count="purchaseSmartwordCount"
					@click-purchase="goToPurchaseWizard"
					@hide="purchasePopupVisible = false"
				/>
			</Transition>
			<Transition name="popup">
				<SettingsPopup
					v-if="settingsPopupVisible"
					:settings="webSite.settings"
					:target-languages="webSite.targetLanguages || []"
					:translated-frame-service="translatedFrame"
					:published-script-to-show="publishedScript"
					@save="saveSettings"
					@cancel="settingsPopupVisible = false"
				/>
			</Transition>
			<Transition name="popup">
				<ScriptPopup
					v-if="showPublishPopup"
					:validation-result="scriptValidationResult"
					:script="publishedScript"
					:validate-in-progress="scriptValidationInProgress"
					@cancel="showPublishPopup = false"
					@click-force-publish="publish(true)"
					@click-validate="publish(false)"
				/>
			</Transition>
		</template>
		<template #frame>
			<iframe/>
		</template>
	</BrowserLayout>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import BrowserLayout from '@/components/widgets/browser-layout/browser-layout.vue';
	import BrowserToolbar from '@/components/widgets/browser-toolbar/browser-toolbar.vue';
	import ToolbarButton from '@/components/widgets/toolbar-button/toolbar-button.vue';
	import BrowserNavigation from '@/components/widgets/browser-navigation/browser-navigation.vue';
	import {
		ApiClient, IContentTranslationProgress, IPageTranslationProgress,
		PageTranslationStatisticsUpdateRequest,
		ScriptPublicationValidationResult,
		SmartWordsInfo,
		WebPagePublishInfoResponse,
		WebSiteInfo,
		WebSiteSettingsDto,
	} from '@/shared';
	import { TranslatedFrameService } from '@/services/translated-frame-service';
	import { originalUrlToProxyUrl, parseProxyUrl, proxyUrlToOriginalUrl } from '@/shared/utils/proxy-url-helper';
	import { defaultLanguages } from '@/shared/resources/languages';
	import { BrowserButtonState } from '@/components/browser/logic/browser-button-state';
	import { HistoryTracker } from '@/components/browser/logic/history-tracker';
	import { RouteMonitor } from '@/components/browser/logic/route-monitor';
	import { ILanguageModel } from '@/components/widgets/languages/language-client';
	import { debounce } from '@/shared/utils/debounce';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import WtPopup from '@/components/widgets/wt-popup/wt-popup.vue';
	import WtTitle from '@/components/widgets/wt-title/wt-title.vue';
	import WtText from '@/components/widgets/wt-text/wt-text.vue';
	import WtCode from '@/components/widgets/wt-code/wt-code.vue';
	import { ScButton, ScNotification } from '@smartcat/design-system-vue2';
	import { LangSynchronizer } from '@/components/browser/logic/lang-synchronizer';
	import { inject } from '@/components/common/di';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';
	import Notifications from '@/components/widgets/notifications/notifications.vue';
	import { MixpanelService } from '@/services/mixpanel-service';
	import PurchasePopup from '@/components/widgets/purchase-popup/purchase-popup.vue';
	import SmartwordIndicator from '@/components/widgets/smartword-indicator/smartword-indicator.vue';
	import SettingsPopup from '@/components/widgets/settings-popup/settings-popup.vue';
	import { ResilientStorage } from '@/shared/utils/resilient-storage';
	import { CleanupButtonState } from '@/components/browser/logic/cleanup-button-state';
	import LoadingError from '@/components/widgets/loading-error/loading-error.vue';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import { showConfirmation } from "@/components/widgets/confirm-popup/show-confirm";
	import ConfirmationsContainer from "@/components/widgets/confirm-popup/confirmations-container.vue";
	import EditDropdown from "@/components/widgets/browser-navigation/edit-dropdown/edit-dropdown.vue";
	import EditDropdownItem from "@/components/widgets/browser-navigation/edit-dropdown/edit-dropdown-item.vue";
	import { AutoTranslationEnabledChecker } from "@/script/utils/auto-translation-enabled-checker";

	function emptyProgress(): IContentTranslationProgress {
		return {
			scTranslatedPercent: 0,
			sourceLocale: "",
			totalTranslated: 0,
			totalTranslatedPercent: 0,
			totalFragmentCount: 0,
			mtTranslatedCount: 0,
			smartcatTranslatedCount: 0,
			inProgressBatchCount: 0,
			mtTranslatedPercent: 0
		}
	}

	@Component({
		components: {
			EditDropdownItem,
			EditDropdown,
			ConfirmationsContainer,
			LoadingError,
			ScButton,
			SettingsPopup,
			SmartwordIndicator,
			PurchasePopup,
			Notifications,
			ScriptPopup,
			BrowserNavigation,
			ToolbarButton,
			BrowserToolbar,
			BrowserLayout,
			WtButton,
			WtPopup,
			WtTitle,
			WtText,
			WtCode,
			ScNotification,
		},
	})
	export default class BrowserPage extends Vue {
		public cleanupButtonState: CleanupButtonState = CleanupButtonState.Hidden;
		public webSite: WebSiteInfo = null;
		public langIsAdding = false;
		public targetLanguage: string = null;
		public initialSyncInProgress = false;
		public pageUrl = '';
		public canonicalUrl = '';
		public authorizationRequired = false;
		public translatedFrame: TranslatedFrameService = null;
		public buttonState = new BrowserButtonState();
		public editInProgress = false;
		public historyTracker = new HistoryTracker();
		public translationProgress: IPageTranslationProgress = {
			main: emptyProgress(),
			sections: [],
			wholePage: emptyProgress()
		};

		public smartwordInfo: SmartWordsInfo = null;
		public routeMonitor = new RouteMonitor(this.$router);
		public langsSynchronizer: LangSynchronizer = null;
		public mixpanelService = inject(MixpanelService);
		public notificationService = inject(NotificationService);
		public apiService = new SmartcatApiService(new ApiClient());
		public storage = new ResilientStorage();

		public scriptValidationInProgress = false;
		public scriptValidationFailed = false;
		public scriptValidationResult: ScriptPublicationValidationResult;
		public publishedScript = '';
		public showPublishPopup: boolean = null;
		public purchasePopupVisible = false;
		public settingsPopupVisible = false;
		public webPagePublishInfo: WebPagePublishInfoResponse | null = null;
		public purchaseSmartwordCount?: number = undefined;
		public frameLoadingFailed = false;
		public frameLoadingTimeout: any;
		public customizationService: CustomizationService;
		public saveProgressOnServer: () => void;

		public get cleanupButtonText() {
			switch (this.cleanupButtonState) {
				case CleanupButtonState.DryRun:
					return 'CleanUp (DryRun)';
				case CleanupButtonState.Production:
					return 'CleanUp (Prod)';
				case CleanupButtonState.ClearAll:
					return 'Clear';
				case CleanupButtonState.ProductionUnconfirmedOnly:
					return 'Clean Up';
			}
			return '-';
		}

		public get showCleanupButton() {
			return (
				(this.translationProgress && this.translationProgress.wholePage.totalTranslated > 0) && (
					this.cleanupButtonState === CleanupButtonState.DryRun ||
					this.cleanupButtonState === CleanupButtonState.Production ||
					this.cleanupButtonState === CleanupButtonState.ProductionUnconfirmedOnly ||
					this.cleanupButtonState === CleanupButtonState.ClearAll)
			);
		}

		public get notSourceLang() {
			return this.webSite && this.targetLanguage !== this.webSite.sourceLanguage;
		}

		public get langs() {
			return (
				this.langsSynchronizer?.langs?.concat([this.webSite.sourceLanguage]) || [this.webSite.sourceLanguage]
			);
		}

		public get settingsAllowPageTranslation() {
			const rules = this.webSite?.settings?.translation?.pathRulesToTranslate;
			if (!rules?.length) {
				return true;
			}
			const isTranslationAllowed = new AutoTranslationEnabledChecker().doesContentUrlMatchTranslationRules(this.pageUrl,
				this.webSite.host,
				rules);
			return isTranslationAllowed;
		}

		public openDashboard() {
			this.$router.push(`/web-site/${this.webSite.id}/pages`);
		}

		public async signIn() {
			const currentUrl = window.location.href;
			const wstAuthenticationUrl = '/integrations/web-translation?backUrl=' + encodeURIComponent(currentUrl);
			const currentUserSettings = await this.apiService.getSettings();
			window.location.href = currentUserSettings.smartcatApiUrl.replace(/\/$/, '')
				+ '/signIn?requiredAccountId=' + this.webSite.accountId
				+ '&backUrl=' + encodeURIComponent(wstAuthenticationUrl);
		}

		public async mounted() {
			const webSiteId = this.$route.params.webSiteId;
			this.webSite = await this.apiService.getWebSite(webSiteId);
			this.customizationService = new CustomizationService({
				rootUrl: this.webSite.rootUrl,
				langs: [this.webSite.sourceLanguage, ...this.webSite.targetLanguages],
			});

			this.initButtonState();

			if (!await this.checkUserAccess()) {
				return;
			}

			this.mixpanelService.trackPreviewBrowserOpened();
			this.saveProgressOnServer = debounce(() => this.saveStatsOnServerPrivate(), 3000);

			const customization = this.customizationService.getCustomization();
			this.langsSynchronizer = new LangSynchronizer(webSiteId, this.webSite.targetLanguages);
			this.pageUrl = (this.$route.query.pageUrl as string) || this.webSite.rootUrl;
			this.targetLanguage =
				customization?.selectLocaleByUrl(this.pageUrl, this.langs) ||
				this.getLangFromQuery() ||
				this.webSite.targetLanguages[0];

			console.log('set targetLanguage ->' + this.targetLanguage);

			await this.runInitialSync();
			await this.initTranslatedFrame(webSiteId);
			this.initRouteMonitor();

			this.smartwordInfo = await this.apiService.getSmartwordsInfo(webSiteId);
			try {
				this.translationProgress = await this.getFrameStats();
				this.updateEditButtons();
			} catch (e) {
				this.buttonState.update(null, this.settingsAllowPageTranslation, false);
				console.error(e);
			}
		}

		private initButtonState() {
			const location = window.location.href.toString();
			if (location.includes('cleanup=dry')) {
				this.cleanupButtonState = CleanupButtonState.DryRun;
			} else if (location.includes('cleanup=prod-unconfirmed')) {
				this.cleanupButtonState = CleanupButtonState.ProductionUnconfirmedOnly;
			} else if (location.includes('cleanup=prod')) {
				this.cleanupButtonState = CleanupButtonState.Production;
			} else if (location.includes('cleanup=all')) {
				this.cleanupButtonState = CleanupButtonState.ClearAll;
			}
			if (this.cleanupButtonState !== CleanupButtonState.Hidden) {
				new ResilientStorage().trySetItem('cleanupButtonState', this.cleanupButtonState);
			} else {
				const savedState: string = new ResilientStorage().tryGetItem('cleanupButtonState');
				if (savedState) {
					this.cleanupButtonState = +savedState;
				} else {
					const customization = this.customizationService.getCustomization();
					if (customization && customization.allowToCleanupPages()) {
						this.cleanupButtonState = CleanupButtonState.ProductionUnconfirmedOnly;
					}
				}
			}
		}

		private async checkUserAccess() {
			const currentUserSettings = await this.apiService.getSettings();
			if (currentUserSettings.currentAccountId !== this.webSite.accountId) {
				this.authorizationRequired = true;
				return false;
			}
			return true;
		}

		private async initTranslatedFrame(webSiteId: string) {
			const getSmartwords = debounce(async () => {
				this.smartwordInfo = await this.apiService.getSmartwordsInfo(webSiteId);
			}, 1000);

			const trackTranslated = debounce(() => {
				this.mixpanelService.trackTranslatedPage(
					this.pageUrl,
					this.webSite.sourceLanguage,
					this.webSite.targetLanguages[0],
				);
			}, 5000);

			const frame = document.getElementsByTagName('iframe')[0];

			this.translatedFrame = new TranslatedFrameService(frame);
			const initialUrl = this.originalUrlToProxyUrl(this.pageUrl, this.targetLanguage);
			this.translatedFrame.userNavigated.addHandler((e) => this.onFrameLoaded(e.url, e.canonicalUrl));
			this.translatedFrame.frameLangChange.addHandler((e) => this.onFrameLangChange(e.locale));
			this.translatedFrame.smartwordsRequired.addHandler(() => this.showPurchasePopup());

			this.translatedFrame.translationStatusUpdated.addHandler(async () => {
				const stats = await this.getFrameStats();
				this.translationProgress = stats;
				const totalTranslated = stats.main.mtTranslatedCount + stats.main.smartcatTranslatedCount;
				if (stats.main.inProgressBatchCount > 0 && totalTranslated == 0) {
					this.buttonState.disableButtons = true;
				} else {
					this.buttonState.disableButtons = false;
				}
				this.buttonState.update(stats, this.settingsAllowPageTranslation, stats.sections.length > 0);
				this.saveProgressOnServer();
				getSmartwords();
				trackTranslated();
			});

			frame.onload = () => {
				this.startFrameLoadTimout();
			};
			this.translatedFrame.init(initialUrl);
		}

		private initRouteMonitor() {
			this.routeMonitor.routeChanged.addHandler((e) => {
				if (!e.pageUrl || !e.lang) {
					return;
				}

				const customization = this.customizationService.getCustomization();
				const targetLang = customization?.selectLocaleByUrl(e.pageUrl, this.langs) || e.lang;
				console.log('Route changed', e.pageUrl, 'e.lang=' + e.lang, 'targetLang=' + targetLang, 'targetLanguage=' + this.targetLanguage);
				const mustReloadFrame = this.pageUrl != e.pageUrl || this.targetLanguage != targetLang;
				this.pageUrl = e.pageUrl;
				this.targetLanguage = targetLang || this.targetLanguage;
				if (mustReloadFrame) {
					this.beginLoadFrame();
					this.mixpanelService.trackNavigatedToOtherPage(this.pageUrl);
				}
				this.getWebPagePublishInfo(this.pageUrl);
			});
			this.routeMonitor.init();
		}

		private updateEditButtons() {

			this.buttonState.update(this.translationProgress, this.settingsAllowPageTranslation,
				this.translationProgress.sections.length > 0)
		}

		private async saveStatsOnServerPrivate() {
			const progress = this.translationProgress;
			const req: PageTranslationStatisticsUpdateRequest = {
				webSiteId: this.webSite.id,
				pagePath: null,
				absoluteUrl: this.pageUrl,
				translations: [
					{
						locale: this.targetLanguage,
						autoTranslationCount: progress.main.mtTranslatedCount,
						scTranslationCount: progress.main.smartcatTranslatedCount,
						segmentCount: progress.main.totalFragmentCount,
					},
				]
			}
			if (progress.sections && progress.sections.length) {
				for (const section of progress.sections) {
					const sectionRequest: PageTranslationStatisticsUpdateRequest = {
						webSiteId: this.webSite.id,
						pagePath: "__sections/" + section.sectionId,
						absoluteUrl: null,
						translations: [
							{
								locale: this.targetLanguage,
								autoTranslationCount: section.mtTranslatedCount,
								scTranslationCount: section.smartcatTranslatedCount,
								segmentCount: section.totalFragmentCount,
							},
						]
					};
					this.apiService.saveStats(sectionRequest).then();
				}
			}
			await this.apiService.saveStats(req);
		}

		async showSettingsPopup() {
			this.settingsPopupVisible = true;
			this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
		}

		async showPurchasePopup() {
			if (this.showPublishPopup) {
				return;
			}
			this.purchaseSmartwordCount = await this.tryGetSmartwordCountForPurchase();
			this.purchasePopupVisible = true;
		}

		public async topUpBalance() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public async goToSubscription() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public async getWebPagePublishInfo(originalUrl: string) {
			this.webPagePublishInfo = await this.apiService.getWebPagePublishInfo(this.webSite.id, originalUrl);
		}

		public goToFaq() {
			window.open('https://www.smartcat.com/smartwords', '_blank');
		}

		async goToPurchaseWizard() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public async openProject() {
			const s = await this.apiService.getSettings();
			const url = s.smartcatApiUrl.replace(/\/$/, '') + '/projects/' + this.webSite.scProjectId + '/files';
			window.open(url, '_self');
		}

		public async saveSettings(settings: WebSiteSettingsDto) {
			try {
				this.buttonState.disableButtons = true;
				await this.apiService.updateSettings(this.webSite.id, settings);
				this.webSite.settings = settings;

				try {
					const response = await this.apiService.getPublishInfo(this.webSite.id);

					if (response.translatorScriptUploaded) {
						await this.apiService.publishTranslator(this.webSite.id);
					}
				} catch (e) {
					console.error(e);
				}

				this.notificationService.showSuccess('Updated settings');
				this.settingsPopupVisible = false;
				this.translatedFrame.reload();
			} finally {
				this.buttonState.disableButtons = false;
			}
		}

		public startFrameLoadTimout() {
			if (this.frameLoadingTimeout) {
				clearTimeout(this.frameLoadingTimeout);
			}
			this.frameLoadingTimeout = setTimeout(() => {
				this.frameLoadingFailed = !this.translatedFrame.isLoaded;
			}, 20000);

		}

		public async onSelectLang(lang: ILanguageModel) {
			if (this.targetLanguage === lang.cultureName) {
				return;
			}

			this.buttonState.reset();
			const isSourceLang = this.webSite.sourceLanguage === lang.cultureName;
			const isExisting = this.webSite.targetLanguages.find((x) => x === lang.cultureName) != null;
			if (!isExisting && !isSourceLang) {
				this.langIsAdding = true;
				try {
					this.webSite = await this.apiService.addLanguage(this.webSite.id, lang.cultureName);
					this.langsSynchronizer.notifyLangAdded(lang.cultureName);

					if (this.webSite.settings.general.translateNewElements) {
						try {
							const response = await this.apiService.getPublishInfo(this.webSite.id);

							if (response.translatorScriptUploaded) {
								await this.apiService.publishTranslator(this.webSite.id);
							}
						} catch (e) {
							console.error(e);
						}
					}
				} finally {
					this.langIsAdding = false;
				}
			}
			this.targetLanguage = lang.cultureName;
			console.log('Selected language', lang.cultureName);
			this.routeMonitor.updateCurrentRoute(this.pageUrl, this.targetLanguage);
			this.beginLoadFrame();
			this.mixpanelService.trackSwitchedLanguage(lang.cultureName);
		}

		public onClickBack() {
			const newUrl = this.historyTracker.movePrevious();
			this.pageUrl = newUrl;
			this.beginLoadFrame();
		}

		public onClickForward() {
			const newUrl = this.historyTracker.moveNext();
			this.pageUrl = newUrl;
			this.beginLoadFrame();
		}

		public onManualUrlInput(newUrl: string) {
			this.pageUrl = newUrl;
			this.beginLoadFrame();
			this.historyTracker.add(newUrl);
		}

		public async editPage() {
			if (!this.translationProgress) {
				return;
			}
			if (this.translationProgress.main.totalFragmentCount === 0) {
				this.notificationService.showError("This page has no translatable content");
				return;
			}
			const pageSection = this.translationProgress.sections.find(x => x.sectionId.indexOf('-page') >= 0);
			if (pageSection) {
				return this.editPageOrSection(null, '__sections/' + pageSection.sectionId);
			}
			return this.editPageOrSection(await this.getCurrentPageUrl());
		}

		public async editSection(sectionId: string) {
			if (!this.translationProgress) {
				return;
			}
			const sectionTranslationProgress = this.translationProgress.sections.find(x => x.sectionId == sectionId);
			if (!sectionTranslationProgress) {
				return;
			}
			if (!sectionTranslationProgress.totalFragmentCount) {
				this.notificationService.showError("This section has no translatable content");
				return;
			}
			return this.editPageOrSection(null, "__sections/" + sectionId);
		}

		public async editPageOrSection(pageUrl: string, pagePath?: string) {
			this.editInProgress = true;
			this.buttonState.disableButtons = true;

			try {
				const syncResults = await this.apiService.syncPageWithSmartcat({
					force: true,
					webSiteId: this.webSite.id,
					pageUrl,
					pagePath,
					fragments: [],
					targetLang: [this.targetLanguage],
				});

				this.mixpanelService.trackEnteredEditMode(pageUrl || pagePath);
				const pageSyncResult = pagePath && pagePath.includes("__section")
					? syncResults.find((x) => x.items?.[0]?.pagePath.includes(pagePath))
					: syncResults.find((x) => !x.items?.[0]?.pagePath.includes('__section'));

				if (pageSyncResult && pageSyncResult.items[0].smartcatDocumentId) {
					const docId = pageSyncResult.items[0].smartcatDocumentId.replace(/_\d*$/, '');
					const s = await this.apiService.getSettings();
					const lang = defaultLanguages().find((x) => x.cultureName === this.targetLanguage);
					const url =
						s.smartcatApiUrl.replace(/\/$/, '') +
						'/editor?v=2&documentId=' +
						docId +
						'&languageId=' +
						lang.id +
						'&backUrl=' +
						encodeURIComponent(window.location.href);
					this.storage.trySetItem('sync-on-start', 'true');
					if (pageUrl) {
						this.storage.trySetItem('sync-on-start-url', pageUrl);
					} else if (pagePath) {
						this.storage.trySetItem('sync-on-start-path', pagePath);
					}
					window.open(url, '_self');
					return;
				}
				const s = await this.apiService.getSettings();
				const url = s.smartcatApiUrl.replace(/\/$/, '') + '/projects/' + this.webSite.scProjectId + '/files';
				window.open(url, '_self');
			} catch (e) {
				console.error(e);
				this.notificationService.showError('An error occurred');
			} finally {
				this.buttonState.disableButtons = false;
				this.editInProgress = false;

			}
		}

		private async showCleanUpConfirmation() {
			const description = this.cleanupButtonState === CleanupButtonState.ClearAll
				? "This will remove all translations for this page and recalculate statistics."
				: "This will remove all unconfirmed translations for this page and recalculate statistics.";

			const isConfirmed = await showConfirmation(
				"Are you sure want to perform cleanup for this page?",
				description,
				"Yes"
			);

			return isConfirmed;
		}

		public async cleanUpTranslations() {
			if (!await this.showCleanUpConfirmation()) {
				return;
			}

			this.buttonState.disableButtons = true;

			try {
				const fragments = await this.translatedFrame.getFragments();
				await this.apiService.cleanupPageTranslations({
					dryRun:
						this.cleanupButtonState == CleanupButtonState.DryRun ||
						this.cleanupButtonState == CleanupButtonState.Hidden,
					webSiteId: this.webSite.id,
					pageUrl: await this.getCurrentPageUrl(),
					preserveFragments: this.cleanupButtonState === CleanupButtonState.ClearAll ? [] : fragments,
					locale: this.targetLanguage,
					deleteUnconfirmedOnly: this.cleanupButtonState === CleanupButtonState.ProductionUnconfirmedOnly,
				});
				inject(NotificationService).showSuccess('Cleanup complete');

				if (this.cleanupButtonState === CleanupButtonState.ClearAll) {
					window.location.reload();
				}
			} catch (e) {
				console.error(e);
				this.notificationService.showError('Cleanup failed');
			} finally {
				this.buttonState.disableButtons = false;
			}
		}

		public async publish(skipValidation = false) {
			this.mixpanelService.trackHitPublish(this.pageUrl);
			this.buttonState.disableButtons = true;
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;

			try {
				await this.apiService.publishTranslator(this.webSite.id);
				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(
						this.webSite.id,
						this.pageUrl || null,
					);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}

						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.showPublishPopup = true;
						return;
					}
					this.mixpanelService.trackJsCodeChecked(this.pageUrl);
				}
				await this.apiService.publish({
					pageUrl: await this.getCurrentPageUrl(),
					webSiteId: this.webSite.id,
					locales: this.webSite.targetLanguages,
				});
				this.mixpanelService.trackTranslationsPublished(this.pageUrl);
				this.showPublishPopup = false;
				inject(NotificationService).showSuccess('Page published');
				await this.getWebPagePublishInfo(this.pageUrl);
			} finally {
				this.scriptValidationInProgress = false;
				this.buttonState.disableButtons = false;
			}
		}

		public async beginTranslation() {
			this.buttonState.disableButtons = true;
			try {
				await this.translatedFrame.beginTranslation();
				this.mixpanelService.trackEnabledPageTranslation();
			} finally {
				this.buttonState.disableButtons = false;
				this.translationProgress = await this.getFrameStats();
				this.updateEditButtons();
			}
		}

		public async getFrameStats(): Promise<IPageTranslationProgress> {
			if (!this.settingsAllowPageTranslation) {
				return {
					main: {
						inProgressBatchCount: 0,
						mtTranslatedCount: 0,
						smartcatTranslatedCount: 0,
						sourceLocale: '',
						totalFragmentCount: 0,
						totalTranslated: 0,
					},
					wholePage: {
						inProgressBatchCount: 0,
						mtTranslatedCount: 0,
						smartcatTranslatedCount: 0,
						sourceLocale: '',
						totalFragmentCount: 0,
						totalTranslated: 0,
					},
					sections: [],
				};
			}

			return await this.translatedFrame.getStats();
		}

		public async sync() {
			await this.apiService.syncPageWithSmartcat({
				webSiteId: this.webSite.id,
				pageUrl: await this.getCurrentPageUrl(),
				fragments: [],
				targetLang: [this.targetLanguage],
				force: true,
			});
			this.translatedFrame.reload();
		}

		public async onFrameLangChange(newLocale: string) {
			const lang = defaultLanguages().find((l) => l.cultureName === newLocale);
			if (!lang) {
				return;
			}
			console.log('Frame lang changed to ' + newLocale, 'current lang=' + this.targetLanguage);
			await this.onSelectLang(lang);
		}

		public async onFrameLoaded(newUrl: string, canonicalUrl: string) {
			const proxiedUrl = parseProxyUrl(newUrl);
			if (proxiedUrl == null) {
				return;
			}
			this.startFrameLoadTimout();
			this.pageUrl = proxyUrlToOriginalUrl(proxiedUrl, this.webSite.host);
			this.canonicalUrl = canonicalUrl || this.pageUrl;

			const customization = this.customizationService.getCustomization();
			const newTargetLangFromUrl = customization?.selectLocaleByUrl(this.pageUrl, this.langs) ||
				proxiedUrl.lang;
			const newTargetLanguage = this.webSite.targetLanguages.find(
				lang => lang.toLowerCase() === newTargetLangFromUrl.toLowerCase()) || this.targetLanguage;

			const isTargetLanguageChanged = this.targetLanguage !== newTargetLanguage;
			console.log('Frame loaded', this.pageUrl, 'targetLanguage=' + this.targetLanguage, 'new newTargetLanguage=' + newTargetLanguage);

			if (customization && isTargetLanguageChanged) {
				this.onFrameLangChange(newTargetLanguage);
				return;
			}
			this.targetLanguage = newTargetLanguage;
			this.routeMonitor.updateCurrentRoute(this.pageUrl, this.targetLanguage);
			if (this.historyTracker.currentUrl !== newUrl) {
				this.historyTracker.add(this.pageUrl);
			}
			this.translationProgress = await this.getFrameStats();
			this.saveProgressOnServer();
			this.updateEditButtons();

			this.mixpanelService.trackNavigatedToOtherPage(this.pageUrl);
			await this.getWebPagePublishInfo(this.canonicalUrl);
		}

		private async beginLoadFrame() {
			this.buttonState.reset();
			// this.runFrameTimeoutWatcher();
			const newUrl = this.originalUrlToProxyUrl(this.pageUrl, this.targetLanguage);
			this.translatedFrame.load(newUrl);
		}

		private originalUrlToProxyUrl(url: string, lang: string) {
			const customization = this.customizationService.getCustomization();
			let proxyUrl = originalUrlToProxyUrl(url, lang, this.webSite.proxyUrlFormat, this.webSite.host);
			if (customization?.canPatchProxyUrl()) {
				proxyUrl = customization.patchProxyUrl(proxyUrl, lang);
			}
			return proxyUrl;
		}

		private async runInitialSync() {
			if (this.storage.tryGetItem('sync-on-start') === 'true') {
				const urlToSync = this.storage.tryGetItem('sync-on-start-url');
				const pathToSync = this.storage.tryGetItem('sync-on-start-path');
				if (urlToSync || pathToSync) {
					try {
						this.mixpanelService.trackReturnedFromEditMode(urlToSync || pathToSync);
						this.initialSyncInProgress = true;
						await this.apiService.beginImportTranslationsFromSmartcat({
							webSiteId: this.webSite.id,
							pageUrl: urlToSync,
							pagePath: pathToSync,
							fragments: [],
							targetLang: [this.targetLanguage],
							force: true,
						});
						this.storage.trySetItem('sync-on-start-url', '');
						this.storage.trySetItem('sync-on-start-path', '');
						this.storage.trySetItem('sync-on-start', 'false');
					} catch (e) {
						console.error(e);
					} finally {
						this.initialSyncInProgress = false;
					}
				}
			}
		}

		private async getCurrentPageUrl() {
			if (this.canonicalUrl) {
				return this.canonicalUrl;
			}
			this.canonicalUrl = await this.translatedFrame.getCanonicalUrl();
			return this.canonicalUrl || this.pageUrl;
		}

		private getLangFromQuery() {
			if ('scProxyLang' in this.$route.query) {
				const lang = this.$route.query.scProxyLang as string;
				if (this.webSite.targetLanguages.includes(lang)) {
					return lang;
				}
			}
		}

		private async tryGetSmartwordCountForPurchase() {
			try {
				const fragments = await this.translatedFrame.getFragmentsThatRequireMoreSmartwords();
				const resp = await this.apiService.countRequiredSmartwords({
					texts: fragments.map((f) => f.text),
					sourceLanguage: this.webSite.sourceLanguage,
					targetLanguage: this.targetLanguage,
				});
				return resp.count;
			} catch {
				return undefined;
			}
		}
	}
</script>

<style lang="less" scoped>
@import '../common/styles/animations';

.popup-enter-active {
	animation: 0.3s;

	/deep/ .wt-popup {
		animation: overlay-fade-in 0.3s;
		animation-fill-mode: forwards;

		& .wt-popup__form {
			animation: popup-slide-in 0.3s;
			animation-fill-mode: forwards;
		}
	}
}

.popup-leave-active {
	animation: 0.3s;

	/deep/ .wt-popup {
		animation: overlay-fade-in 0.3s reverse;
		animation-fill-mode: forwards;

		& .wt-popup__form {
			animation: popup-slide-in 0.3s reverse;
			animation-fill-mode: forwards;
		}
	}
}
</style>
