import { ShopifyBaseSectionHandler } from '@/script/logic/customization/shopify-base-section-handler';
import { kebabCase } from '@/script/utils/kebab-case';
import { sanitize } from "@/script/utils/sanitize";

export class TravelProSectionHandler extends ShopifyBaseSectionHandler {
	protected knownWords = new Set([
		'add',
		'select',
		'total',
		'enter-shipping-address',
		'shipping',
		'subtotal',
		'apply-gift-card',
		'apply',
		'discount-code',
		'perfect-picks-to-pair-with',
		'quantity',
		'modify',
		'discount-calculating-retry',
		'discount-code-not-applicable-to-offers-or-sets',
		'retry',
		'verified-buyer'
	]);


	protected isHeader() {
		return this.isAncestorOrItemMatchSelector('[data-section-type="header-section"]');
	}

	protected isFooter() {
		return this.isAncestorOrItemMatchSelector('#shopify-section-footer');
	}

	protected isShoppingCart() {
		return this.isAncestorOrItemMatchSelector('.inner-mini-cart');
	}

	protected isProductInCart() {
		return this.isAncestorOrItemMatchSelector('.cart-list ul li');
	}

	protected isCollectionsFilter() {
		return this.isAncestorOrItemMatchSelector('.ns-sidebar-wrapper');
	}

	protected isCollectionsSorting() {
		return this.isAncestorOrItemMatchSelector('.ns-selection-dropdown-sort-menu');
	}

	protected isProductCard() {
		return this.isAncestorOrItemMatchSelector('.ns-product, .grid__item-prod')
	}

	protected isProductCardTexts() {
		return this.isAncestorOrItemMatchSelector('.selected-text')
			|| this.isAncestorOrItemMatchSelector('.sr-only.visually-hidden')
			|| this.isAncestorOrItemMatchSelector('.pro_type');
	}

	protected isInstagramSection() {
		return this.isAncestorOrItemMatchSelector('[id="insta-feed"]');
	}

	protected isInstagramDialog() {
		return this.isAncestorOrItemMatchSelector('.lightbox-instagram');
	}

	protected getCheckoutSection() {
		if (this.isAncestorOrItemMatchSelector('aside')) {
			const productRow = this.el.closest('[role="row"]');
			const isProduct = productRow?.querySelector('img');
			if (isProduct && this.el.textContent) {
				const kebabCaseText = kebabCase(this.el.textContent);
				if (this.knownWords.has(kebabCaseText) || kebabCaseText.includes('subtotal')) {
					return 'checkout-page';
				}
				const productName = productRow.querySelector('p');
				return 'dynamic/checkout/' + kebabCase(productName.textContent);
			}
		}
		return 'checkout-page';
	}


	public getSectionId() {

		const productLink = this.el.closest('a[href]');
		if (productLink) {
			const href = productLink.getAttribute('href');
			if (href && href.indexOf('/products/') > 0) {
				const productName = href.split('/products/')[1].split('?')[0];
				return 'dynamic/product/' + sanitize(productName)
			}
		}
		if (this.isHeader()) {
			return 'header';
		}
		if (this.isFooter()) {
			return 'footer';
		}
		if (this.isProductCardTexts()) {
			return 'product'
		}
		if (this.isProductInCart()) {
			return this.getProductInCartSection('.cart-list ul li');
		}
		if (this.isShoppingCart()) {
			return this.getShoppingCartSection('.inner-mini-cart');
		}
		if (this.isProductCard()) {
			const selector = '.ns-product, .grid__item-prod'
			const productCard = this.getProductCardSectionBySelector(selector);
			if (productCard) {
				return productCard;
			}
			return 'product'
		}
		if (this.isCollectionsFilter()) {
			return 'collections-filter'
		}
		if (this.isCollectionsSorting()) {
			return 'collections-sorting'
		}
		if (this.isInstagramSection() || this.isInstagramDialog()) {
			return 'instagram'
		}
		if (this.isCollections()) {
			return this.getCollectionsSection();
		}
		if (this.isProductsPage()) {
			return this.getProductSection();
		}
		if (this.isCheckouts()) {
			return this.getCheckoutSection();
		}
		if (this.isBlogsPage()) {
			return 'blogs-page';
		}
	}
}
