export function sanitize(text: string) {
	let url = text;
	// check if / is first character
	if (url[0] === '/') {
		url = url.substring(1);
	}
	return decodeURIComponent(url)
		.replace(/\s+/g, '-')
		.replace(/[^a-zA-Z0-9\-\/]/g, '')
		.replace(/-{2,}/g, '-')
		.toLowerCase();
}
