import { CustomizationService } from '@/script/logic/customization/customizations';
import { getPathSimple } from '@/script/logic/translation/get-path-simple';

export class UrlFormattingService {
	customizationService: CustomizationService;

	constructor(customizationService: CustomizationService) {
		this.customizationService = customizationService;
	}

	public formatTranslationUrl(format: string, webSiteId: string, pageUrl: string, locale?: string): string {
		return format
			.replace('{pagePath}', this.getPagePath(pageUrl))
			.replace('{pageUrl}', encodeURIComponent(pageUrl))
			.replace('{webSiteId}', webSiteId)
			.replace('{locale}', locale);
	}

	public getPagePath(pageUrl: string) {
		const customization = this.customizationService.getCustomization();
		if (customization && customization.canGetPath()) {
			return customization.getPath(pageUrl);
		}
		return getPathSimple(pageUrl);
	}
}
