<template>
	<BrowserLayout
		:is-loading="translatedFrame && !translatedFrame.isLoaded"
		:error="frameLoadingFailed"
	>
		<template #toolbar>
			<Transition name="popup">
				<SignInPopup
					v-if="showContinuePopup"
					@hide="hideSignUpPopup"
					@click-sign-up="signUp"
					@click-sign-in="signUp"
				/>
			</Transition>
			<SignUpDrawer
				:is-show="showSignUpDrawer"
				@close="showSignUpDrawer = false"
				@click-sign-up="drawerSignUp"
			/>
			<BrowserToolbar @close="openRootPage">
				<template
					v-if="webSite"
					#buttons
				>
					<WtButton
						:async-action="tryToEdit"
						:disabled="translatedFrame && translatedFrame.isProxyTranslating"
						icon="edit"
						label="Edit"
					/>
				</template>
				<template #side-buttons>
					<Transition name="opacity">
						<WtButton
							v-if="showToolbarSignUpButton && !showSignUpDrawer && !showContinuePopup"
							class="sign-up-to-free"
							label="Sign up for free"
							view="cta-gradient"
							:async-action="() => signUp()"
						/>
					</Transition>
				</template>
				<template
					v-if="webSite"
					#navigation
				>
					<BrowserNavigation
						:selected-lang="language"
						:url="pageUrl"
						:stats="stats"
						:machine-translation-spin="translatedFrame.isProxyTranslating"
						:forward-disabled="true"
						:backward-disabled="true"
						:source-lang="webSite.sourceLanguage"
						:langs="webSite.targetLanguages.concat([webSite.sourceLanguage])"
						@navigate="() => showSignUpPopup('navigationAttempt')"
						@select-lang="tryToSwitchLang"
					/>
				</template>
			</BrowserToolbar>
		</template>
		<template #frame>
			<iframe id="iframe"/>
		</template>
	</BrowserLayout>
</template>

<script lang="ts">
	import { Component, Vue } from 'vue-property-decorator';
	import BrowserLayout from '@/components/widgets/browser-layout/browser-layout.vue';
	import BrowserToolbar from '@/components/widgets/browser-toolbar/browser-toolbar.vue';
	import ToolbarButton from '@/components/widgets/toolbar-button/toolbar-button.vue';
	import BrowserNavigation from '@/components/widgets/browser-navigation/browser-navigation.vue';
	import { ApiClient, get, IPageTranslationProgress, WebSiteInfo } from '@/shared';
	import { TranslatedFrameService } from '@/services/translated-frame-service';
	import { originalUrlToProxyUrl, parseProxyUrl, proxyUrlToOriginalUrl } from '@/shared/utils/proxy-url-helper';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import SignInPopup from '@/components/widgets/sign-in-popup/sign-in-popup.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import { MixpanelService, SignUpPopupSource } from '@/services/mixpanel-service';
	import { ILanguageModel } from '@/components/widgets/languages/language-client';
	import { inject } from '@/components/common/di';
	import langs from '@/resources/languages.json';
	import SignUpDrawer from '@/components/widgets/bottom-popup/bottom-popup.vue';
	import { RouteMonitor } from '@/components/browser/logic/route-monitor';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import { UrlFormattingService } from '@/script/logic/translation/url-formatting-service';

	@Component({
		components: {
			SignInPopup,
			ScriptPopup,
			BrowserNavigation,
			ToolbarButton,
			BrowserToolbar,
			BrowserLayout,
			WtButton,
			SignUpDrawer,
		},
	})
	export default class DemoBrowserPage extends Vue {
		public webSite: WebSiteInfo = null;
		public language: string = null;
		public pageUrl = '';
		public translatedFrame: TranslatedFrameService = null;
		public stats: IPageTranslationProgress = null;
		public showContinuePopup = false;
		public signUpPopupSource: SignUpPopupSource = 'toolbar';
		public mixpanelService = inject(MixpanelService);
		public apiService = new SmartcatApiService(new ApiClient());
		public routeMonitor = new RouteMonitor(this.$router);
		public showSignUpDrawer = false;
		public translationFinished = false;
		public showToolbarSignUpButton = false;
		public frameLoadingFailed = false;
		public frameLoadingTimeout: any;

		public async mounted() {
			window.addEventListener('keydown', this.onKeyDown);
			window.focus();
			const webSiteId = this.$route.params.webSiteId;
			this.webSite = await this.apiService.getWebSite(webSiteId);
			this.language = this.getLangFromQuery() || this.webSite.targetLanguages[0];
			this.pageUrl = (this.$route.query.pageUrl as string) || this.webSite.rootUrl;

			const frame = document.getElementsByTagName('iframe')[0];
			const initialUrl = originalUrlToProxyUrl(this.pageUrl, this.language, this.webSite.proxyUrlFormat, this.webSite.host);
			this.translatedFrame = new TranslatedFrameService(frame);

			if (this.$route.query.share && !this.webSite.isSharingEnabled) {
				await this.apiService.enableSharing(this.webSite.id);
				this.webSite.isSharingEnabled = true;
			}

			this.initFrameEventHandlers(frame);
			this.routeMonitor.routeChanged.addHandler((e) => {
				if (!e.pageUrl || !e.lang) {
					return;
				}

				const mustReloadFrame = this.pageUrl != e.pageUrl || this.language != e.lang;
				this.pageUrl = e.pageUrl;
				this.language = e.lang || this.language;

				if (mustReloadFrame) {
					this.beginLoadFrame();
				}
			});

			this.routeMonitor.init();
			this.translatedFrame.init(initialUrl);
			// this.runFrameTimeoutWatcher();

			if (!this.webSite.isSharingEnabled) {
				await this.translatedFrame.blockLinks();
			}

			this.mixpanelService.trackDemoBrowserOpened(this.webSite.isSharingEnabled);
		}

		public onKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				e.preventDefault();

				this.showSignUpDrawer = false;
			}
		}

		openRootPage() {
			window.location.href = '/';
		}

		public initFrameEventHandlers(frame: HTMLIFrameElement) {
			this.translatedFrame.frameInitialized.addHandler(() => {
				this.onFrameInitialized();
			});

			frame.onload = () => {
				this.onFrameInitialized();
			};

			this.translatedFrame.userNavigated.addHandler(async () => {
				const proxyUrl = await this.translatedFrame.getLocation();
				const url = proxyUrlToOriginalUrl(parseProxyUrl(proxyUrl), this.webSite.host);
				this.pageUrl = url;

				const customizationService = new CustomizationService({
					rootUrl: this.webSite.rootUrl,
					langs: [this.webSite.sourceLanguage, ...this.webSite.targetLanguages],
				});
				const formattingService = new UrlFormattingService(customizationService);

				if (this.webSite.isSharingEnabled) {
					this.routeMonitor.updateCurrentRoute(this.pageUrl, this.language);
					this.mixpanelService.trackDemoTriedToNavigate(url, this.webSite.isSharingEnabled);
				} else if (formattingService.getPagePath(url) !== formattingService.getPagePath(this.webSite.rootUrl)) {
					this.showSignUpPopup('navigationAttempt');
					this.mixpanelService.trackDemoTriedToNavigate(url, this.webSite.isSharingEnabled);
				}
			});


			this.translatedFrame.translationStatusUpdated.addHandler(async () => {
				const stats = await this.translatedFrame.getStats();
				this.stats = stats;
				const translationFinished = this.stats.main.totalTranslated > 0 && stats.main.inProgressBatchCount === 0;
				if (translationFinished) {
					this.translationFinished = translationFinished;
					this.showToolbarSignUpButton = true;
					this.mixpanelService.trackDemoTranslatedPage(
						this.pageUrl,
						this.webSite.sourceLanguage,
						this.webSite.targetLanguages[0],
						this.webSite.isSharingEnabled,
					);
				}
			});

			this.translatedFrame.frameLangChange.addHandler((e) => {
				const lang = langs.find((l) => l.cultureName === e.locale);
				this.tryToSwitchLang(lang);
			});

			this.translatedFrame.userLinkClick.addHandler(({ url }) => {
				this.showSignUpPopup('navigationAttempt');
				this.mixpanelService.trackDemoTriedToNavigate(url, this.webSite.isSharingEnabled);
			});
		}

		private runFrameTimeoutWatcher() {
			this.frameLoadingFailed = false;
			clearTimeout(this.frameLoadingTimeout);
			this.frameLoadingTimeout = setTimeout(() => {
				this.frameLoadingFailed = true;
			}, 30000);
		}

		private async beginLoadFrame() {
			// this.runFrameTimeoutWatcher();
			const newUrl = originalUrlToProxyUrl(this.pageUrl, this.language, this.webSite.proxyUrlFormat, this.webSite.host);
			this.translatedFrame.load(newUrl);
		}

		public async tryToEdit() {
			this.mixpanelService.trackDemoTriedToEdit();
			this.showSignUpPopup('editAttempt');
		}

		public tryToSwitchLang(lang: ILanguageModel) {
			const isSourceLang = this.webSite.sourceLanguage === lang.cultureName;
			const isExisting = this.webSite.targetLanguages.find((x) => x === lang.cultureName) != null;

			if (this.webSite.isSharingEnabled && (isExisting || isSourceLang)) {
				this.language = lang.cultureName;
				this.routeMonitor.updateCurrentRoute(this.pageUrl, this.language);
				if (this.pageUrl) {
					this.beginLoadFrame();
				}
			} else {
				this.showSignUpPopup('languageSwitchAttempt');
			}
			this.mixpanelService.trackDemoTriedToSwitchLanguage(lang.cultureName, this.webSite.isSharingEnabled);
		}

		public async signUp() {
			this.mixpanelService.trackDemoSignUpClicked(this.signUpPopupSource, this.webSite.isSharingEnabled);
			const authInfo = await get<any>('/authenticate/info?demoWebSiteId=' + this.webSite.id);
			window.open(authInfo.smartcatAuthenticationUrl, '_self');
		}

		public async drawerSignUp() {
			this.signUpPopupSource = 'drawer';
			await this.signUp();
		}

		public showSignUpPopup(source: SignUpPopupSource) {
			this.showContinuePopup = true;
			this.signUpPopupSource = source;
			this.showSignUpDrawer = false;
		}

		public hideSignUpPopup() {
			this.showContinuePopup = false;
			this.signUpPopupSource = 'toolbar';
			this.mixpanelService.trackDemoPopupClosed();
		}

		public async onFrameInitialized() {
			clearTimeout(this.frameLoadingTimeout);
			this.frameLoadingFailed = !this.translatedFrame.isLoaded;
		}

		private getLangFromQuery() {
			if ('scProxyLang' in this.$route.query) {
				const lang = this.$route.query.scProxyLang as string;
				if (this.webSite.targetLanguages.includes(lang)) {
					return lang;
				}
			}
		}
	}
</script>
<style scoped lang="less" src="./demo-browser-page.less"/>
